import { PatientAddress } from '@revival/scheduler';

export const addNewPatientAddress = async (
  patientId: string,
  address: Partial<PatientAddress>,
  token: string | null,
) => {
  const url = `${process.env.REACT_APP_REVIVAL_API_URL}/v1/patient/${patientId}/address/new`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(address),
    });
    const data = await response.json();
    return {
      status: response.status,
      ...data,
    };
  } catch (error) {
    console.error('ERROR::addNewPatientAddress: ', error);
    return { success: false, data: null };
  }
};
