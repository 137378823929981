import { AppointmentReminder, PatientAddress } from '@revival/scheduler';
import { create } from 'zustand';
import { addDays } from 'date-fns';
import { addNewPatientAddress } from '../services/revival';

type State = {
  reminder: AppointmentReminder | null;
  selectedAddress: PatientAddress | null;
  availableAppointmentSlots: any;
  selectedDate: Date | null;
};

type Actions = {
  setReminder: (reminder: AppointmentReminder) => void;
  setSelectedAddress: (address: PatientAddress | null) => void;
  setAvailableAppointmentSlots: (slots: any) => void;
  setSelectedDate: (date: Date) => void;
  addNewPatientAddress: (
    patientId: string,
    address: Partial<PatientAddress>,
    token: string | null,
  ) => Promise<any>; //TODO: define return type
};

type SchedulerStore = State & Actions;

export const useSchedulerStore = create<SchedulerStore>((set) => ({
  reminder: null,
  selectedAddress: null,
  availableAppointmentSlots: {
    '7:30 AM': true,
    '8:15 AM': false,
    '9:00 AM': false,
    '9:45 AM': true,
    '10:30 AM': true,
    '11:15 AM': true,
  },
  selectedDate: addDays(new Date(), 1),
  setReminder: (reminder) => set({ reminder }),
  setSelectedAddress: (address) => set({ selectedAddress: address }),
  setAvailableAppointmentSlots: (slots) =>
    set({ availableAppointmentSlots: slots }),
  setSelectedDate: (date) => set({ selectedDate: date }),
  addNewPatientAddress: async (patientId, address, token) => {
    const { success, data } = await addNewPatientAddress(
      patientId,
      address,
      token,
    );
    set({ selectedAddress: data });
    return { success, data };
  },
}));
