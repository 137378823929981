import { Box, Button, Card, CardBody, Flex, VStack } from '@chakra-ui/react';
import { AppointmentInfo } from './AppointmentInfo';
import { AppointmentCalendar } from './AppointmentCalendar';
import React from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { useSchedulerStore } from '../../stores/SchedulerStore';

export const AppointmentPanel = () => {
  const reminder = useSchedulerStore((state) => state.reminder);
  const selectedAddress = useSchedulerStore((state) => state.selectedAddress);
  const setSelectedAddress = useSchedulerStore(
    (state) => state.setSelectedAddress,
  );
  return (
    <Card
      rounded={0}
      padding={0}
      // minW={'600px'}
      maxW={'1050px'}
      m={'0 auto auto auto'}
    >
      <CardBody p={0}>
        <Flex
          gap={{ lg: '25px' }}
          flexDirection={{
            base: 'column',
            lg: 'row',
          }}
        >
          <VStack flex={2} alignItems={'left'} gap={0}>
            <Box p={5} textAlign={'left'}>
              <Button
                variant={'link'}
                p={2}
                style={{
                  border: '1px solid red ',
                  borderColor: 'var(--chakra-colors-chakra-border-color)',
                  borderRadius: '50%',
                }}
                onClick={() => setSelectedAddress(null)}
              >
                <HiArrowLeft
                  color={'var(--chakra-colors-messenger-500)'}
                  size={35}
                />
              </Button>
            </Box>
            {reminder && selectedAddress && (
              <AppointmentInfo
                reminder={reminder}
                selectedAddress={selectedAddress}
              />
            )}
          </VStack>
          <AppointmentCalendar />
        </Flex>
      </CardBody>
    </Card>
  );
};
