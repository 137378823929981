import React, { useEffect } from 'react';
import { Logo } from './components/Logo';
import { Box, Center, Container, SlideFade, Spinner } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetchData from './hooks/useFetchData';
import { AppointmentReminder } from '@revival/scheduler';
import { SelectAddressPanel } from './components/address/SelectAddressPanel';
import { AppointmentPanel } from './components/calendar/AppointmentPanel';
import { useSchedulerStore } from './stores/SchedulerStore';
import useToken from './hooks/useToken';

function App() {
  const { reminderId } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();

  const setAppointments = useSchedulerStore(
    (state) => state.setAvailableAppointmentSlots,
  );
  const selectedDate = useSchedulerStore((state) => state.selectedDate);
  const selectedAddress = useSchedulerStore((state) => state.selectedAddress);
  const setReminder = useSchedulerStore((state) => state.setReminder);

  const {
    data: reminder,
    loading,
    error,
  } = useFetchData<AppointmentReminder>(
    `${process.env.REACT_APP_REVIVAL_API_URL}/v1/reminder/${reminderId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  useEffect(() => {
    if (reminder) {
      console.log('reminder:', reminder);
      setReminder(reminder);
    }

    if (error || (!loading && !reminder)) {
      navigate('/404');
    }
  }, [loading]);

  useEffect(() => {
    //TODO: REVIV-5: refactor to retrieve appointment slots from server
    if (selectedDate) {
      // simulate fetching appointments from the server
      setAppointments({
        '7:30 AM': true,
        '8:15 AM': false,
        '9:00 AM': false,
        '9:45 AM': true,
        '10:30 AM': true,
        '11:15 AM': true,
      });
    }
  }, [selectedDate]);

  return (
    <Container maxW={{ md: '100vw', xl: '75vw' }}>
      <Box
        w={{ base: '50vw', lg: '25vw' }}
        h={{ base: '40vw', lg: '20vw' }}
        m={'0 auto'}
        maxW={'360px'}
        maxH={'360px'}
        mt={-10}
      >
        <Logo />
      </Box>

      {reminder && !selectedAddress && (
        <SlideFade
          offsetY="20px"
          in={true}
          transition={{ exit: { delay: 0 }, enter: { duration: 0.5 } }}
        >
          <SelectAddressPanel />
        </SlideFade>
      )}

      {reminder && selectedAddress && (
        <SlideFade
          offsetY="20px"
          in={true}
          transition={{ exit: { delay: 0 }, enter: { duration: 0.5 } }}
        >
          <AppointmentPanel />
        </SlideFade>
      )}

      {loading && (
        <Center>
          <Spinner size={'xl'} />
        </Center>
      )}
    </Container>
  );
}

export default App;
