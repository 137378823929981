import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import Calendar from './Calendar';
import { addDays, format } from 'date-fns';
import { FaGlobeAmericas } from 'react-icons/fa';
import { useSchedulerStore } from '../../stores/SchedulerStore';

export const AppointmentCalendar = () => {
  const today = new Date();
  const startDate = addDays(today, 1);

  const selectedDate = useSchedulerStore((state) => state.selectedDate);
  const setSelectedDate = useSchedulerStore((state) => state.setSelectedDate);

  const appointments = useSchedulerStore(
    (state) => state.availableAppointmentSlots,
  );
  const setAppointments = useSchedulerStore(
    (state) => state.setAvailableAppointmentSlots,
  );

  const toggleAppointment = (time: string) => {
    setAppointments({
      ...appointments,
      [time]: !appointments[time],
    });
  };
  return (
    <Box
      w={{ base: '100%' }}
      p={5}
      borderLeft={'var(--chakra-colors-chakra-border-color) 1px solid'}
    >
      <Box mb={'15px'}>
        <Text fontSize={'lg'} fontWeight={'bold'} pl={2}>
          Select a Date & Time
        </Text>
      </Box>
      <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={'25px'}>
        <Box w={{ base: '100%' }}>
          <Calendar
            setSelectedDate={setSelectedDate}
            minDate={startDate}
            maxDate={addDays(startDate, 30)}
            disabledWeekDays={[0, 6]}
          />

          <Flex mt={5}>
            <Box p={1}>
              <FaGlobeAmericas />
            </Box>
            <Text fontSize={'sm'}>
              {Intl.DateTimeFormat().resolvedOptions().timeZone} -{' '}
              {format(today, 'p')}
            </Text>
          </Flex>
        </Box>

        <VStack spacing={3} w={{ base: '100%', lg: '50%' }} m={'0 auto'}>
          {selectedDate && appointments && (
            <>
              <Text textAlign={'left'} w={'100%'} h={'32px'} pt={1}>
                {format(selectedDate, 'eeee, MMMM dd')}
              </Text>

              {Object.keys(appointments).map((time) => (
                <Button
                  key={time}
                  colorScheme={appointments[time] ? 'messenger' : 'gray'}
                  onClick={() => toggleAppointment(time)}
                  isDisabled={!appointments[time]}
                  w={'100%'}
                  size={'lg'}
                >
                  {time}
                </Button>
              ))}
            </>
          )}
        </VStack>
      </Flex>
    </Box>
  );
};
