import { useLocation } from 'react-router-dom'; // assuming you're using react-router for routing
import { useMemo } from 'react';

// Defining the hook return type
type UseTokenReturnType = {
  token: string | null;
};

// Hook implementation
const useToken = (): UseTokenReturnType => {
  const location = useLocation(); // Get the current location object
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const token = query.get('token'); // Extract the token query parameter

  return { token };
};

export default useToken;
